import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  styled,
  TableCell,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import StyledSelectInput from "../../Common/StyledSelectInput";
import { getTimeSlots } from "../../../api/Customer/enquiry";
import { updateTrip } from "../../../api/Admin/trips";
import useToast from "../../../hooks/useToast";

const EditTrip = ({
  enquiryDetails,
  customerAmounts,
  transporterAmounts,
  tripId,
  getTripById,
  handleCloseModal,
}) => {
  const [timeSlots, setTimeSlots] = useState([]);
  const { handleOpenToast, ToastMessage } = useToast();

  const { pickup, distance, material, quantity, weight } = enquiryDetails || {};

  const { LEMargin, finalAmount } = customerAmounts || {};
  const { finalBidAmount: transporterBidAmount } = transporterAmounts || {};

  const transporterRate = transporterBidAmount / (weight / 1000);

  const customerRate = finalAmount / (weight / 1000);

  const [editedWeight, setEditedWeight] = useState(weight);
  const [editedTransporterRate, setEditedTransporterRate] = useState(
    Math.ceil(transporterRate)
  );
  const [editedCustomerRate, setEditedCustomerRate] = useState(
    Math.ceil(customerRate)
  );
  const [editedTransporterBidAmount, setEditedTransporterBidAmount] = useState(
    Math.ceil(transporterBidAmount)
  );
  const [editedLEMargin, setEditedLEMargin] = useState(Math.ceil(LEMargin));
  const [editedTotalAmount, setEditedTotalAmount] = useState(
    Math.ceil(finalAmount)
  );
  const [editedPickupDate, setEditedPickupDate] = useState(
    moment(pickup?.date).format("YYYY-MM-DD")
  );
  const [editedPickupTime, setEditedPickupTime] = useState(pickup?.slot || "");

  const handleEditWeight = (e) => {
    const newWeight = e.target.value;
    const updatedTransporterBidAmount = Math.ceil(
      (newWeight / 1000) * editedTransporterRate
    );
    const updatedTotalAmount = Math.ceil(
      (newWeight / 1000) * editedCustomerRate
    );
    const updatedLEMargin = updatedTotalAmount - updatedTransporterBidAmount;

    setEditedWeight(newWeight);
    setEditedTransporterBidAmount(updatedTransporterBidAmount);
    setEditedTotalAmount(updatedTotalAmount);
    setEditedLEMargin(updatedLEMargin);
  };

  const handleEditTransporterRate = (e) => {
    const newTransporterRate = e.target.value;
    const updatedTransporterBidAmount = Math.ceil(
      (editedWeight / 1000) * newTransporterRate
    );
    const updatedLEMargin = editedTotalAmount - updatedTransporterBidAmount;

    setEditedTransporterRate(newTransporterRate);
    setEditedTransporterBidAmount(updatedTransporterBidAmount);
    setEditedLEMargin(updatedLEMargin);
  };

  const handleEditCustomerRate = (e) => {
    const newCustomerRate = e.target.value;
    const updatedTotalAmount = Math.ceil(
      (editedWeight / 1000) * newCustomerRate
    );
    const updatedLEMargin = updatedTotalAmount - editedTransporterBidAmount;

    setEditedCustomerRate(newCustomerRate);
    setEditedTotalAmount(updatedTotalAmount);
    setEditedLEMargin(updatedLEMargin);
  };

  const getSlots = async () => {
    try {
      const res = await getTimeSlots();
      const timeSlotForDropdown = res.data.data.map((slot, idx) => ({
        id: idx,
        value: slot.timeSlot,
        startTime: slot.startTime,
      }));
      setTimeSlots(timeSlotForDropdown);
    } catch (error) {
      handleOpenToast("Failed to get time slots", "error");
    }
  };

  const handleEditTrip = async () => {
    const data = {
      weight: editedWeight,
      quantity,
      customerFinalAmount: editedTotalAmount,
      transporterFinalAmount: editedTransporterBidAmount,
      LEMargin: editedLEMargin,
      tripDate: editedPickupDate,
      tripTimeSlot: editedPickupTime,
    };

    try {
      await updateTrip(tripId, data);
      getTripById();
      handleCloseModal();
      handleOpenToast("Trip Updated Successfully", "success");
    } catch (error) {
      handleOpenToast("Failed to update trip", "error");
    }
  };

  useEffect(() => {
    getSlots();
  }, []);

  return (
    <Dialog open fullWidth maxWidth="lg">
      <ToastMessage />
      <DialogContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6" fontWeight="bold">
            Current Trip Details
          </Typography>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
        <Box
          display="flex"
          flexDirection={"column"}
          justifyContent="flex-start"
          m={2}
        >
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <DescriptionBox>
              <HeadingTableCell>Material Type</HeadingTableCell>
              <DescriptionTableCell>
                {material?.name.length > 20
                  ? `${material?.name.substring(0, 20)}...`
                  : material?.name}
              </DescriptionTableCell>
            </DescriptionBox>
            <DescriptionBox>
              <HeadingTableCell>Weight</HeadingTableCell>
              <DescriptionTableCell>
                {weight ? `${weight} KG` : "-"}
              </DescriptionTableCell>
            </DescriptionBox>
            <DescriptionBox>
              <HeadingTableCell>No Of Pkgs</HeadingTableCell>
              <DescriptionTableCell>{quantity || "-"}</DescriptionTableCell>
            </DescriptionBox>
            <DescriptionBox>
              <HeadingTableCell>Distance</HeadingTableCell>
              <DescriptionTableCell>
                {distance ? `${distance} KM` : "-"}
              </DescriptionTableCell>
            </DescriptionBox>
            <DescriptionBox>
              <HeadingTableCell>Transporter Rate</HeadingTableCell>
              <DescriptionTableCell>
                ₹ {transporterRate ? Math.ceil(transporterRate) : "-"}
              </DescriptionTableCell>
            </DescriptionBox>
            <DescriptionBox>
              <HeadingTableCell>Customer Rate</HeadingTableCell>
              <DescriptionTableCell>
                ₹ {customerRate ? Math.ceil(customerRate) : "-"}
              </DescriptionTableCell>
            </DescriptionBox>
            <DescriptionBox>
              <HeadingTableCell>Transporter Quote</HeadingTableCell>
              <DescriptionTableCell>
                ₹ {transporterBidAmount ? transporterBidAmount : "-"}
              </DescriptionTableCell>
            </DescriptionBox>
            <DescriptionBox>
              <HeadingTableCell>LE Margine</HeadingTableCell>
              <DescriptionTableCell>
                ₹ {LEMargin ? LEMargin : "-"}
              </DescriptionTableCell>
            </DescriptionBox>
            <DescriptionBox>
              <HeadingTableCell>Final Amount</HeadingTableCell>
              <DescriptionTableCell>
                ₹ {finalAmount ? finalAmount : "-"}
              </DescriptionTableCell>
            </DescriptionBox>
          </Box>
          <Box display="flex" justifyContent="flex-start" mt={4}>
            <DescriptionBox>
              <HeadingTableCell>Pick Up Date</HeadingTableCell>
              <DescriptionTableCell>
                {moment(pickup?.date).format("DD-MM-YYYY")}
              </DescriptionTableCell>
            </DescriptionBox>
            <DescriptionBox>
              <HeadingTableCell>Pick Up Time</HeadingTableCell>
              <DescriptionTableCell>{pickup?.slot || "-"}</DescriptionTableCell>
            </DescriptionBox>
          </Box>
        </Box>
        <Typography variant="h6" fontWeight="bold" mb={2}>
          Edit And Update Trip Details
        </Typography>
        <Box
          display="flex"
          flexDirection={"column"}
          justifyContent="flex-start"
          m={2}
        >
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <DescriptionBox>
              <HeadingTableCell>Material Type</HeadingTableCell>
              <DescriptionTableCell>
                {material?.name.length > 20
                  ? `${material?.name.substring(0, 20)}...`
                  : material?.name}
              </DescriptionTableCell>
            </DescriptionBox>
            <DescriptionBox>
              <HeadingTableCell>Weight</HeadingTableCell>
              <InputField
                value={editedWeight}
                variant="outlined"
                size="small"
                onChange={handleEditWeight}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">KG</InputAdornment>
                  ),
                }}
              />
            </DescriptionBox>
            <DescriptionBox>
              <HeadingTableCell>No Of Pkgs</HeadingTableCell>
              <DescriptionTableCell>
                {quantity ? quantity : "-"}
              </DescriptionTableCell>
            </DescriptionBox>
            <DescriptionBox>
              <HeadingTableCell>Distance</HeadingTableCell>
              <DescriptionTableCell>
                {distance ? `${distance} KM` : "-"}
              </DescriptionTableCell>
            </DescriptionBox>
            <DescriptionBox>
              <HeadingTableCell>Transporter Rate</HeadingTableCell>
              <InputField
                placeholder="5000"
                value={editedTransporterRate}
                onChange={handleEditTransporterRate}
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹/Tone</InputAdornment>
                  ),
                }}
              />
            </DescriptionBox>
            <DescriptionBox>
              <HeadingTableCell>Customer Rate</HeadingTableCell>
              <InputField
                placeholder="5115"
                variant="outlined"
                value={editedCustomerRate}
                onChange={handleEditCustomerRate}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹/Tone</InputAdornment>
                  ),
                }}
              />
            </DescriptionBox>
            <DescriptionBox>
              <HeadingTableCell>Transporter Quote</HeadingTableCell>
              <DescriptionTableCell>
                ₹{editedTransporterBidAmount ? editedTransporterBidAmount : 0}
              </DescriptionTableCell>
            </DescriptionBox>
            <DescriptionBox>
              <HeadingTableCell>LE Margine</HeadingTableCell>
              <DescriptionTableCell>
                ₹{editedLEMargin ? editedLEMargin : 0}
              </DescriptionTableCell>
            </DescriptionBox>
            <DescriptionBox>
              <HeadingTableCell>Final Amount</HeadingTableCell>
              <DescriptionTableCell>
                ₹{editedTotalAmount ? editedTotalAmount : 0}
              </DescriptionTableCell>
            </DescriptionBox>
          </Box>
          <Box display="flex" justifyContent="flex-start" mt={4}>
            <DescriptionBox>
              <HeadingTableCell>Pick Up Date</HeadingTableCell>
              <InputField
                type="date"
                value={editedPickupDate}
                onChange={(e) => setEditedPickupDate(e.target.value)}
                variant="outlined"
                size="small"
              />
            </DescriptionBox>
            <DescriptionBox>
              <Box>
                <HeadingTableCell>Pick Up Date</HeadingTableCell>

                <StyledSelectInput
                  name="slot"
                  onChange={(e) => setEditedPickupTime(e.target.value)}
                  items={timeSlots}
                  value={editedPickupTime}
                />
              </Box>
            </DescriptionBox>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" mt={4}>
          <Button variant="contained" color="success" onClick={handleEditTrip}>
            Submit
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditTrip;

const HeadingTableCell = styled(TableCell)({
  fontWeight: "bold",
  fontSize: "13px",
  border: "none",
  height: "50px",
  textAlign: "start",
});

const DescriptionTableCell = styled(TableCell)({
  fontSize: "13px",
  border: "none",
  height: "50px",
});

const DescriptionBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "200px",
});

const InputField = styled(TextField)({
  width: "150px",
  padding: "0px 4px",
  alignSelf: "center",
  justifyContent: "center",
  height: "50px",
});
