import React, { useState, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router";
import useToast from "../../../hooks/useToast";
import TripDetails from "./TripDetails";
import TransporterDetails from "./TransporterDetails";
import VehicleDetails from "./VehicleDetails";
import CustomerDetails from "./CustomerDetails";
import TransporterPaymentDetails from "./TransporterPaymentDetails";
import CustomerReceiptDetails from "./CustomerReceiptDetails";
import { getTripDetails } from "../../../api/Admin/trips";
import { TRIP_STATUS_ARRAY } from "../../../constants/constant";
import CancelDetails from "./CancelDetails";
import DeliveryProof from "./DeliveryProof";

export default function TripDetailedView() {
  const [tripData, setTripData] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const { handleOpenToast, ToastMessage } = useToast();

  const getTripById = async () => {
    try {
      const response = await getTripDetails(id);
      if (response.status === 200) {
        setTripData(response.data.data);
      }
    } catch (error) {
      handleOpenToast("Error while getting enquiry.", "error");
    }
  };

  useEffect(() => {
    getTripById();
  }, [id]);

  const {
    enquiryDetails,
    transporterAmounts,
    customerAmounts,
    transporterDetails,
    vehicleDetails,
    driver,
    customerDetails,
  } = tripData || {};

  return (
    <Box>
      <ToastMessage />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ background: "#EAEAEA", padding: "12px 24px" }}
      >
        <HeaderBox>
          <img
            style={{
              cursor: "pointer",
              width: "17px",
              height: "24px",
            }}
            src="/assets/icons/backArrow.svg"
            alt="back"
            onClick={() => navigate(-1)}
            height="24px"
          />
          <StyledTypography
            fontWeight="700"
            fontSize="16px"
            textTransform="capitalize"
          >
            #{tripData?.tripId}
          </StyledTypography>
        </HeaderBox>
        <StyledTypography
          fontWeight="700"
          fontSize="24px"
          textTransform="capitalize"
        >
          {tripData?.route}
        </StyledTypography>
        <HeaderBox gap="18px">
          <StyledTypography
            fontWeight="700"
            fontSize="12px"
            textTransform="capitalize"
            color={
              TRIP_STATUS_ARRAY.find(
                (status) => status.label === tripData?.tripStatus
              )?.color
            }
          >
            {
              TRIP_STATUS_ARRAY.find(
                (status) => status.label === tripData?.tripStatus
              )?.value
            }
          </StyledTypography>
        </HeaderBox>
      </Stack>
      <TripDetails
        enquiryDetails={enquiryDetails}
        transporterAmounts={transporterAmounts}
        customerAmounts={customerAmounts}
        tripStatus={tripData?.tripStatus}
        tripId={tripData?.id}
        vehicleCategory={vehicleDetails?.vehicleCategory}
        handleOpenToast={handleOpenToast}
        getTripById={getTripById}
        vehicleName={vehicleDetails?.vehicleModelName}
      />
      <TransporterDetails transporterDetails={transporterDetails} />
      <VehicleDetails vehicleDetails={vehicleDetails} driver={driver} />
      <CustomerDetails customerDetails={customerDetails} />
      <TransporterPaymentDetails
        tripData={tripData}
        getTripById={getTripById}
        handleOpenToast={handleOpenToast}
      />
      <CustomerReceiptDetails
        tripData={tripData}
        getTripById={getTripById}
        handleOpenToast={handleOpenToast}
      />
      {tripData?.tripStatus?.includes("CANCELLED") && (
        <CancelDetails
          tripData={tripData}
          handleOpenToast={handleOpenToast}
          getTripById={getTripById}
        />
      )}
      <DeliveryProof
        tripData={tripData}
        getTripById={getTripById}
        handleOpenToast={handleOpenToast}
        enquiryDetails={enquiryDetails}
        customerAmounts={customerAmounts}
        transporterAmounts={transporterAmounts}
        tripId={tripData?.id}
      />
    </Box>
  );
}

const HeaderBox = styled(Box)(({ gap }) => ({
  display: "flex",
  flexDirection: "col",
  alignItems: "center",
  gap: gap || "12px",
}));

const StyledTypography = styled(Typography)(
  ({ fontWeight, fontSize, textTransform }) => ({
    fontWeight: fontWeight || "",
    fontSize: fontSize || "",
    textTransform: textTransform || "",
  })
);
